import React from 'react';
import { updateCustomerAttributes, updateCustomerAttributesInput } from '@msdyn365-commerce-modules/retail-actions';
import { IAccountLoyaltyResources } from '../../definition-extensions/account-loyalty.ext.props.autogenerated';
import axios from 'axios';
import { IAccountLoyaltyData } from '@msdyn365-commerce-modules/account-management';
import { ICoreContext } from '@msdyn365-commerce/core';

interface ILostYourCardProps {
    resources: IAccountLoyaltyResources;
    onSubmit: () => void;
    onCancel: () => void;
    disableCardBtn: () => void;
    userToken: string | false;
    cardNumber: string | undefined;
    data: IAccountLoyaltyData;
    context: ICoreContext;
    tierId?: string;
    clubImage?: string;
    clubPlusImage?: string;
}

const LostYourCard = (props: ILostYourCardProps) => {
    const {
        resources: {
            requestNewCardHeading,
            lostYourCardDescriptionText,
            proceedText,
            cancelText,
            requestReceivedText,
            requestReceivedHeading,
            lostCardLabel
        },
        onCancel,
        onSubmit,
        disableCardBtn,
        tierId,
        clubImage,
        clubPlusImage
    } = props;

    // React.useEffect(() => {
    //     replaceCard()
    // },[])

    const replaceCard = async () => {
        const userToken = props.userToken;
        await axios({
            method: 'POST',

            url: `${props.context.request.apiSettings.baseUrl}/Commerce/UpdateReplacementCard`,
            data: {
                CardNumber: props.cardNumber
            },
            headers: {
                'Content-Type': 'application/json',
                OUN: props.context.request.apiSettings.oun,
                Authorization: `id_token ${userToken}`
            }
        })
            .then(res => {
                // @ts-ignore
                const customer = props.data?.customerInformation.result!;
                const customerAttr = customer.Attributes;
                // @ts-ignore
                const newAttributes = customerAttr?.map(attr => {
                    let attrValue = attr.AttributeValue;
                    if (attr.Name === 'LostCardDate') {
                        const value = res.data.Date;

                        attrValue = { ...attr.AttributeValue, StringValue: value };
                    }
                    return { ...attr, AttributeValue: attrValue };
                });
                const input = new updateCustomerAttributesInput(
                    customer.AccountNumber,
                    newAttributes || [],
                    props.context.request.apiSettings
                );
                updateCustomerAttributes(input, props.context.actionContext)
                    .then(response => {
                        onCancel();
                        disableCardBtn();
                    })
                    .catch(err => {
                        console.log(err, 'ERROR');
                    });
            })
            .catch(err => {
                console.log(err, 'Error');
            });
    };

    const handleSubmit = () => {
        replaceCard();
        onSubmit();
    };
    const [isSubmitted] = React.useState(false);
    const currentHeading = isSubmitted ? requestReceivedHeading : lostCardLabel;
    const currentDescription = isSubmitted ? requestReceivedText : lostYourCardDescriptionText;
    const currentButtons = (
        <>
            <button className='cta__btn' onClick={handleSubmit}>
                {proceedText}
            </button>
            <a className='go-back' onClick={onCancel}>
                {cancelText}
            </a>
        </>
    );
    const imgSrc = tierId?.toLowerCase() === 'club' ? clubImage : clubPlusImage;

    return (
        <div className='ms-dobbies-lost-card'>
            {<h2 className='ms-account-loyalty__heading'>{currentHeading}</h2>}
            <div className='lost-your-card-container'>
                <div className='lost-your-card-details'>
                    <h4>{requestNewCardHeading}</h4>
                    <p>{currentDescription}</p>

                    <div className='lost-your-card-links link-holder'>{currentButtons}</div>
                </div>
                <div className='membership-card'>
                    <img src={imgSrc} alt='Card Image' />
                </div>
            </div>
        </div>
    );
};

export default LostYourCard;
