import React from 'react';
import { IAccountLoyaltyResources, IAccountLoyaltyConfig } from '../../definition-extensions/account-loyalty.ext.props.autogenerated';

interface ICancelMembershipProps {
    resources: IAccountLoyaltyResources;
    expireDate?: string;
    onSubmit: () => void;
    onCancel: () => void;
    config: IAccountLoyaltyConfig;
}

const CancelMembership: React.FC<ICancelMembershipProps> = props => {
    const {
        resources: {
            clubBenefitsHeading,
            clubplusSubheading,
            clubSubheading,
            clubBenefit1,
            clubBenefit2,
            clubBenefit3,
            clubBenefit4,
            clubBenefit5,
            clubBenefit6,
            clubBenefit7,
            clubPlusPoints,
            clubPlusCostText,
            clubCost,
            continueMembershipBtn,
            cancelMembershipBtn,
            homeButtonLabel
        },
        expireDate,
        onCancel,
        onSubmit,
        config
    } = props;

    const [showMessage, setShowMessage] = React.useState(false);

    const handleSubmit = () => {
        setShowMessage(true);
        onSubmit();
    };

    const formatDate = (date: string) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = String(d.getFullYear()).slice(-2);
        return ` ${day}/${month}/${year}. `;
    }

    const renderMessage = () => {
        return (
            <div className='membership-expire'>
                <div className='membership-expire-details'>
                        <h2>{config.membershipexpireLabel}</h2>
                        <p>
                            {expireDate && config.cardExpireLabelDecription?.replace('{date}', formatDate(expireDate))}
                        </p>
                    </div>
                <div className='membership-card'>
                    <img
                        src='https://images-eu-prod.cms.commerce.dynamics.com/cms/api/nctrkxsknc/imageFileData/ME18eS?pubver=0'
                        alt='card img'
                    />
                </div>
                <div className='membership-home'>
                    <a className='msc-cta__primary' href='/' >
                        {homeButtonLabel}
                    </a>
                </div>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <div className='ms-loyalty-plus'>
                <div className='cancel-membership-head'>
                    {config.cancelFormHeading && <h2 className='ms-account-loyalty__heading'>{config.cancelFormHeading}</h2>}
                    <p>{config.cancelFormDescription}</p>
                </div>
                <div className='club-benefits'>
                    <h3>{clubBenefitsHeading}</h3>
                    <div className='benefits-table'>
                        <div className='table-head'>
                            <div className='th-club-plus'>{clubplusSubheading}</div>
                            <div className='th-club'>{clubSubheading}</div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit1}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>
                                    <i className='fas fa-check' />
                                </div>
                                <div className='club-cell'>
                                    <i className='fas fa-times' />
                                </div>
                            </div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit2}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>
                                    <i className='fas fa-check' />
                                </div>
                                <div className='club-cell'>
                                    <i className='fas fa-times' />
                                </div>
                            </div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit3}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>{clubPlusPoints}</div>
                                <div className='club-cell'>{clubPlusPoints}</div>
                            </div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit4}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>
                                    <i className='fas fa-check' />
                                </div>
                                <div className='club-cell'>
                                    <i className='fas fa-times' />
                                </div>
                            </div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit5}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>
                                    <i className='fas fa-check' />
                                </div>
                                <div className='club-cell'>
                                    <i className='fas fa-times' />
                                </div>
                            </div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit6}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>
                                    <i className='fas fa-check' />
                                </div>
                                <div className='club-cell'>
                                    <i className='fas fa-times' />
                                </div>
                            </div>
                        </div>
                        <div className='benefit-cell'>
                            <p className='description'>{clubBenefit7}</p>
                            <div className='checks'>
                                <div className='clubplus-cell'>{clubPlusCostText.replace("{club-plus-cost}", config.clubPlusCostValue!)}</div>
                                <div className='club-cell'>{clubCost}</div>
                            </div>
                        </div>
                    </div>
                    <div className='button-wrapper'>
                        <button onClick={onCancel} className='continue-btn'>
                            {continueMembershipBtn}
                        </button>
                        <button onClick={handleSubmit} className='cancel-btn'>
                            {cancelMembershipBtn}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return <div>{showMessage ? renderMessage() : renderForm()}</div>;
};

export default CancelMembership;
